let ENV = {
  PROD: {
    WEB_URL: 'https://now.menu',
    API_URL: 'https://new.plugd.app',
    API_URL_LEGACY: 'https://plugd.app:4000',
    SOCKET_URL: 'https://socket.plugd.app',
    PANEL_URL: 'https://plugd.app/restaurants',
    BASE_URL: '/'
  },
  STAGE: {
    WEB_URL: 'https://dev.plugd.app/plugdWeb',
    API_URL: 'https://dev.plugd.app:4000',
    SOCKET_URL: 'https://dev.plugd.app:3000',
    PANEL_URL: 'https://dev.plugd.app/restaurants',
    BASE_URL: '/'
  },
  STAGING: {
    WEB_URL: 'https://staging.now.menu',
    API_URL: 'https://staging.plugd.app',
    SOCKET_URL: 'https://stage.socket.plugd.app',
    BASE_URL: '/'
  },
  DEV: {
    WEB_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:4000',
    PANEL_URL: 'http://localhost/restaurants',
    SOCKET_URL: 'http://localhost:3001',
    BASE_URL: '/'
    // WEB_URL: 'https://plugdweb.qwikq.net',
    // API_URL: 'https://nodeplugd.qwikq.net',
    // SOCKET_URL: 'https://dev.plugd.app:3000',
    // PANEL_URL: 'https://phpplugd.qwikq.net/plugdapi/restaurants',
    // BASE_URL: '/'
  }
};

let CONSTANTS = {
  BASE_URL: `${ENV[process.env.REACT_APP_STAGE].BASE_URL}`,
  PANEL_URL: `${ENV[process.env.REACT_APP_STAGE].PANEL_URL}`,
  API_URL: `${
    ENV[process.env.REACT_APP_STAGE].API_URL_LEGACY || ENV[process.env.REACT_APP_STAGE].API_URL
  }`,
  WEB_URL: `${ENV[process.env.REACT_APP_STAGE].WEB_URL}`,
  SOCKET_URL: `${ENV[process.env.REACT_APP_STAGE].SOCKET_URL}`,
  APP_TITLE: 'Plugd Admin',
  CACHE_KEY_PREFIX: 'plugd_admin',
  DB_CACHE_KEY_PREFIX: 'plugd_location_',
  DB_MAIN_CACHE_KEY_PREFIX: 'plugd_main',
  CACHE_KEY_PREFIX_ANALYTICS: 'plugd_admin_location_analytics',
  WEB_PUSH_ID: process.env.REACT_APP_WEB_PUSH_ID,
  CLOUD_IMAGE_URL: process.env.REACT_APP_CLOUD_IMAGE_URL,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  STRIPE_PUBLIC_KEY:
    'pk_live_51IpXQ0GW90bZiXDtThPS0d8cbw0IKAcza8P6FMLr29KbgdP8GRi0f6NofL7E8uOCJ194gXJZoI1rRBu7jZGtdvGQ00PPfNsJGn',
  REQUEST_HEADERS: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  INPUTS: {
    DISPLAY_TYPES: {
      RADIO: 'radio',
      SELECT: 'select',
      CHECKBOX: 'checkbox'
    }
  },
  PROMOTIONS: {
    VARIABLES: {
      TYPE: {
        AUTOMATIC: 1,
        MANUAL: 0
      },
      APPLICABLE_TIME_TYPE: {
        ALL_TIME: 0,
        SPECIFIC_DATES: 1,
        WEEK_DAYS: 2
      },
      DISCOUNTS: {
        TYPE: {
          BASKET: 0,
          ALL_ITEMS: 1,
          SPECIFIC_ITEMS: 2
        },
        TYPE_STR: {
          BASKET: 'basket',
          ALL_ITEMS: 'all_items',
          CATEGORIES: 'categories',
          ITEMS: 'items'
        },
        AMOUNT_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        REWARD_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        ITEM_TYPES: {
          CATEGORY: 0,
          ITEM: 1
        }
      }
    }
  },
  ORDERS: {
    LIVE_STATUS_IDS: [11, 12, 13, 14, 21, 22],
    VARIABLES: {
      QR_ERROR:
        'Invalid Restaurant or QR Code. Please contact restaurant manager to resolve this issue.',
      TOTALS: {
        TAX: 'tax',
        SUB_TOTAL: 'cart_total',
        DISCOUNT: 'discount',
        TOTAL: 'order_total'
      },
      ORDER_TYPES: {
        DELIVERY: 'delivery',
        DINE_IN: 'dine_in',
        PICK_UP: 'pick_up',
        CURBSIDE_PICKUP: 'curbside_pickup'
      },
      ORDER_TYPE_STATUSES: {
        DELIVERY: [11, 12, 13, 22, 15, 19],
        DINE_IN: [11, 12, 13, 14, 15, 19],
        PICK_UP: [11, 12, 13, 21, 15, 19],
        CURBSIDE_PICKUP: [11, 12, 13, 21, 15, 19]
      },
      PAYMENT_MODES: {
        CASH: 'cash',
        CARD: 'card'
      },
      PAYMENT_PROVIDERS: {
        ADYEN: 'Adyen',
        CYBERSOURCE: 'CyberSource'
      },
      PAYMENT_STATUSES: {
        PAID: 'paid',
        NOT_PAID: 'not_paid',
        BILL_REQUESTED: 'bill_requested',
        COMPLETE_REFUND: 'complete_refund',
        PARTIAL_REFUND: 'partial_refund',
        HOLD: 'hold'
      },
      STATUSES: {
        PLACED: 'Placed',
        SEEN: 'Seen',
        BEING_PREPARED: 'Being Prepared',
        READY: 'Ready For Pickup',
        ON_THE_WAY: 'On The Way',
        COMPLETE: 'Complete',
        CANCELLED: 'Cancelled'
      },
      PAYMENT_METHODS: {
        scheme: 'Card',
        cash: 'Cash',
        default: 'Cash'
      },
      TIME_OPTIONS: {
        NOW: 'Now',
        TODAY: 'Today',
        TOMORROW: 'Tomorrow'
      },
      DEFAULT_LOCATION: {
        lat: 8.7832,
        lng: 124.5085
      },
      DELIVERY_ZONE_TYPES: {
        CIRCLE: 'circle',
        SHAPE: 'shape'
      }
    }
  },
  URLS: {
    SESSION_ACTIVITY: {
      GET: '/session'
    },
    LOGIN: '/sessions/login',
    LOGOUT: '/sessions/logout',
    REFRESH_TOKEN: '/sessions/refresh',
    INVENTORY: {
      CREATE: (location_id) => `/locations/${location_id}/inventory/raw`,
      GET_ALL: (location_id) => `/locations/${location_id}/inventory?group_type=INVENTORY_TYPES`,
      GET_ONE: (location_id, inventory_id) => `/locations/${location_id}/inventory/${inventory_id}`,
      UPDATE: (location_id, inventory_id) => `/locations/${location_id}/inventory/${inventory_id}`,
      GET_RECIPE_UNITS: (location_id, inventory_id) =>
        `/locations/${location_id}/inventory/${inventory_id}/units?unit_type=RECIPE_UNIT`,
      GET_PURCHASE_UNITS: (location_id, inventory_id) =>
        `/locations/${location_id}/inventory/${inventory_id}/units?unit_type=PURCHASE_UNIT`
    },
    ORDERS: {
      CREATE: (locationId) => `/customers/orders/${locationId}/place_order`,
      UPDATE: (locationId, orderId) => `/customers/orders/${locationId}/update_order/${orderId}`,
      CREATE_ORDER: (locationId) => `/customers/orders/${locationId}/place_user_order`,
      CREATE_HISTORIES: `/customers/orders/statushistories`,
      UPDATE_ORDER: (locationId, orderId) =>
        `/customers/orders/${locationId}/update_user_order/${orderId}`,
      DEFAULT: (locationId) => `/locations/${locationId}/orders`,
      PROV_ORDERS: (locationId) => `/locations/${locationId}/orders/provs`,
      GET_DELIVERY_PROCESSORS: (locationId, orderId) =>
        `/locations/${locationId}/orders/${orderId}/delivery_processors`,
      ADD_RIDERS: (locationId, orderId) => `/locations/${locationId}/orders/${orderId}/addRiders`,
      CANCEL_RIDER_REQUEST: (locationId, orderId) =>
        `/locations/${locationId}/orders/${orderId}/cancel`,
      LIST: '/orders/live',
      GET_RIDER_LOCATION: (locationId, orderId) =>
        `/locations/${locationId}/orders/${orderId}/delivery_details`
    },
    USER_SUBSCRIPTIONS: {
      DEFAULT: '/user_subscriptions'
    },
    CONFIGURATIONS: {
      DEFAULT: '/configurations'
    },
    LOCATIONS: {
      DEFAULT: '/locations',
      ALL_LOCATIONS: '/locations/all',
      CHECK_SLUG: '/locations/checkSlug',
      ENABLE_OUTLETS: '/locations/enableOutlets',
      UPDATE_TIMINGS: (locationId) => `/locations/${locationId}/timings`,
      GET_DELIVERY_PROCESSORS: (locationId) => `/locations/${locationId}/deliveryProcessors`,
      OUTLETS: (locationId) => `/locations/${locationId}/outlets`,
      DEPARTMENTS: (locationId) => `/locations/${locationId}/departments`,
      DELIVERIES: (locationId) => `/locations/${locationId}/deliveries`
    },
    ORDER_TYPES: {
      DEFAULT: (locationId) => `/locations/${locationId}/order_types`,
      DEFAULT_POS: (locationId) => `/locations/${locationId}/order_types_pos`
    },
    BRANDING: {
      DEFAULT: (locationId) => `/locations/${locationId}/layouts`,
      PAGE: (locationId) => `/locations/${locationId}/layouts/pagelayout`
    },
    USERS: {
      DEFAULT: '/users',
      RIDERS: (locationId) => `/users/riders/${locationId}`,
      CUSTOMERS: (locationId) => `/users/${locationId}/customers`,
      SEND_URL: (locationId) => `/sms/${locationId}/customer`,
      CUSTOMERS_LOCATIONS: (locationId) => `/users/${locationId}/customers/locations`
    },
    PAYMENT_PROCESSORS: {
      DEFAULT: (locationId) => `/locations/${locationId}/payment_processors`
    },
    DELIVERY_PROCESSORS: {
      DEFAULT: (locationId) => `/locations/${locationId}/delivery_processors`
    },
    ANALYTICS: {
      DEFAULT: (locationId, filter) => `/locations/${locationId}/analytics/${filter}`,
      RESTAURANTS: (filter) => `/analytics/${filter}`
    },
    MENU: {
      DEFAULT: (locationId) => `/locations/${locationId}/menus`,
      GETMENU: (locationId) => `/customers/locations/${locationId}/menu`,
      GET_MENU_BY_ID: (locationId, menuId) => `/locations/${locationId}/menus/${menuId}`,
      GETMENUPOS: (locationId) => `/customers/locations/${locationId}/menu/pos`,
      BULK_UPDATE_PRIORITY: (locationId) => `/locations/${locationId}/menus/bulkUpdate`,
      MENU_UPDATE_OPTIONS: (locationId, menuId) =>
        `/locations/${locationId}/menus/${menuId}/update`,
      MENU_UPDATE_STATUS: (locationId, menuId) => `/locations/${locationId}/menus/${menuId}/status`,
      GET_DUMMY_ITEM: (locationId) => `/locations/${locationId}/menus/dummy_item`,
      GET_MODIFIERS: (locationId, menuId) => `/locations/${locationId}/menus/${menuId}/modifiers`,
      GET_LIST_MENU: (locationId) => `/locations/${locationId}/menus/list`,
      GET_ALL_MODIFIERS: (locationId) => `/locations/${locationId}/menus/all/modifiers`
    },
    MENU_OPTIONS: {
      DEFAULT: (locationId) => `/locations/${locationId}/options`,
      FILE: (locationId) => `/locations/${locationId}/options/file`
    },
    CATEGORY: {
      DEFAULT: (locationId) => `/locations/${locationId}/categories`,
      GET: (locationId) => `/locations/${locationId}/categories/get`,
      BULK_UPDATE_PRIORITY: (locationId) => `/locations/${locationId}/categories/bulkUpdate`
    },
    PROMOTION: {
      DEFAULT: (locationId) => `/locations/${locationId}/promotions`,
      FILTERED: (locationId) => `/locations/${locationId}/promotions/filtered`
    },
    LOYALTY: {
      DEFAULT: (locationId) => `/locations/${locationId}/loyalties`,
      FILTERED: (locationId) => `/locations/${locationId}/loyalties/filtered`,
      REWARDLEVELS: (locationId) => `/locations/${locationId}/loyalties/rewardlevels`
    },
    BINDISCOUNT: {
      DEFAULT: (locationId) => `/locations/${locationId}/bindiscounts`,
      FILTERED: (locationId) => `/locations/${locationId}/bindiscounts/filtered`
    },
    PROMOTIONS: {
      DEFAULT: (locationId) => `/customers/promotions/${locationId}`,
      CHECK: (code, locationId) => `/customers/promotions/${locationId}/checkpos/${code}`,
      USER_DEFAULT: (locationId) => `/customer/promotions/${locationId}`,
      USER_CHECK: (code, locationId) => `/customer/promotions/${locationId}/checkpos/${code}`
    },
    SMS_CAMPAIGNS: {
      DEFAULT: (locationId) => `/locations/${locationId}/sms_campaigns`,
      CUSTOMERS: (locationId, filter) => `/locations/${locationId}/sms_campaigns/${filter}`,
      CANCEL_CAMPAIGN: (locationId, campaign_id) =>
        `/locations/${locationId}/sms_campaigns/cancel/${campaign_id}`,
      DELETE_CAMPAIGN: (locationId, campaign_id) =>
        `/locations/${locationId}/sms_campaigns/delete/${campaign_id}`
    },
    SMS: {
      DEFAULT: '/sms_subcriptions',
      ALL_SUBSCRIPTIONS: '/sms_subcriptions/show',
      MANUAL_DEFAULT: '/sms_subcriptions/manual',
      MANUAL_DELETE: (planid) => `/sms_subcriptions/manual/${planid}`,
      SUBSCRIBE: `/sms_subscribe`,
      ALL_PLANS: `/sms_subscribe/plans`,
      SUBSCRIBED: `/sms_subscribe/subscribed`,
      SUBSCRIPTIONS: `/sms_subscribe/subscriptions`,
      CARDS: `/sms_subscribe/cards`,
      SMSPLAN: (planid) => `/sms_subcriptions/${planid}`
    },
    REVIEWS: {
      DEFAULT: (locationId) => `/admin/${locationId}/reviews`,
      DELETE: (locationId) => `/admin/${locationId}/reviews/delete`
    },
    APPNOTIFICATIONS: {
      DEFAULT: (locationId) => `/admin/${locationId}/notifications`
    },
    RIDERS: {
      DEFAULT: (locationId) => `/admin/${locationId}/riders`,
      HISTORY: (locationId) => `/admin/${locationId}/riders/history`,
      EARNINGDETAILS: (riderId, locationId) => `/admin/${locationId}}/riders/earnings/${riderId}`,
      PAYMENTDETAILS: (riderId, locationId) => `/admin/${locationId}/riders/payments/${riderId}`,
      PAYMENTS: (locationId) => `/admin/${locationId}/riders/paymentshistory`,
      DELETE: (locationId) => `/admin/${locationId}/riders/delete`
    },
    ADMIN: {
      DEFAULT: '/admin/users',
      UPDATE_BAN: '/admin/users/ban',
      ALL_REPORTS: '/admin/users/reports',
      DEL_REPORT: '/admin/users/reports/delete',
      ALL_FEEDBACKS: '/admin/feedbacks',
      ALL_COUNTRIES: '/admin/countries',
      UPLOADUSERS: (locationId) => `/users/${locationId}/upload`,
      GETUPLOADUSERS: (locationId) => `/users/${locationId}/uploadedusers`,
      TABLES: {
        DEFAULT: (locationId) => `/admin/${locationId}/tables`,
        GET_TABLE: (locationId, tableId) => `/admin/${locationId}/tables/${tableId}`,
        STATUSES: (locationId) => `/admin/${locationId}/tables/status`,
        STATUSESID: (locationId, table) => `/admin/${locationId}/tables/status/${table}`,
        QR: (locationId) => `/admin/${locationId}/tables/qr`
      },
      CURRENCY: {
        DEFAULT: '/admin/currencies',
        GET_CURRENCY: (currencyId) => `/admin/currencies/${currencyId}`,
        STATUSES: '/admin/currencies/status'
      },
      PAGES: {
        DEFAULT: (locationId) => `/admin/${locationId}/pages`,
        GET_PAGE: (pageId, loctionId) => `/admin/${loctionId}/pages/${pageId}`
      },
      OTPSETTINGS: {
        DEFAULT: '/admin/otp',
        GET_OTPSETTINGS: (settingId) => `/admin/otp/${settingId}`,
        STATUSES: '/admin/otp/status',
        WHATSAPP: '/admin/otp/whatsapp'
      },
      BINBANKS: {
        DEFAULT: '/admin/binbanks',
        CARDTYPES: '/admin/binbanks/cardtypes',
        SEARCHBIN: (bin) => `/admin/binbanks/search/${bin}`,
        GET_BINBANK: (binbankId) => `/admin/binbanks/${binbankId}`
      },
      METATAGS: {
        DEFAULT: (locationId) => `/admin/${locationId}/meta`,
        SITEMAP: (locationId) => `/admin/${locationId}/meta/sitemap`
      },
      APPSETTINGS: {
        DEFAULT: '/admin/appsettings',
        ACTIVITIES: '/admin/activities'
      },
      STAFF: {
        DEFAULT: '/admin/staff',
        GET_STAFF: (staffId) => `/admin/staff/${staffId}`,
        GET_ALL_STAFF: `/admin/staff/all`,
        STATUSES: '/admin/staff/status',
        BLACKLISTED: '/admin/staff/blacklist',
        ROLES: '/admin/staff/roles',
        ASSIGN: '/admin/staff/assign',
        DEL: '/admin/staff/del',
        SEARCH: '/admin/staff/search',
        SEARCH_RIDER: '/admin/staff/searchrider'
      },
      ROLES: {
        DEFAULT: '/admin/roles',
        GET_ROLE: (roleId) => `/admin/roles/${roleId}`,
        STATUSES: '/admin/roles/status'
      },
      ACCESS: {
        DEFAULT: '/admin/access',
        GET_PACKAGE: (packageId) => `/admin/access/${packageId}`,
        GET_MODULES: '/admin/access/modules',
        DELETE: '/admin/access/delete',
        ASSIGN_FEATURE: (locationId) => `/admin/${locationId}/access/assign`,
        ASSIGNED_FEATURE: (locationId) => `/admin/${locationId}/access/assigned`
      }
    }
  },
  ACTIONS: {
    SESSION_ACTIVITY: {
      ALL_SESSIONS_ACTIVITY: 'ALL_SESSIONS_ACTIVITY',
      SESSION_ACTIVITY_FILTER_UPDATE: 'SESSION_ACTIVITY_FILTER_UPDATE',
      FILTER_TYPE: 'FILTER_TYPE',
      CLEAR_FILTERS: 'CLEAR_FILTERS',
      SET_EMPTY_LIST: 'SET_EMPTY_LIST'
    },
    INVENTORY: {
      ALL_INVENTORY: 'GET_ALL_INVENTORY_ITEMS',
      INVENTORY_ITEMS_BY_GROUP_ID: 'INVENTORY_ITEMS_BY_GROUP_ID',
      SPECIFIC_INVENTORY_ITEM: 'GET_SPECIFIC_INVENTORY_ITEM',
      GET_RECIPE_UNITS: 'GET_RECIPE_UNITS',
      GET_PURCHASE_UNITS: 'GET_PURCHASE_UNITS'
    },
    ANALYTICS: {
      OVERALL: 'GET_OVERALL_STATS',
      RESTAURANTS: 'GET_RESTAURANTS_STATS',
      CUSTOMERS: 'GET_CUSTOMER_STATS',
      ITEMS: 'GET_ITEM_STATS',
      BINORDERS: 'GET_BINORDERS',
      BINS: 'GET_BINS',
      ORDERS: 'GET_ORDER_STATS',
      ACTIVITIES: 'GET_ACTIVITIES_STATS',
      PAYMENTLOGS: 'GET_PAYMENT_LOGS_STATS'
    },
    CATEGORIES: {
      CATEGORY_MENU_UPDATE: 'UPDATE_CATEGORY_WITH_MENU',
      CATEGORY_MENU: 'GET_CATEGORY_WITH_MENU',
      GET_CATEGORY: 'GET_CATEGORY',
      GET_ERROR_CATEGORY: 'GET_ERROR_CATEGORY'
    },
    PROMOTIONS: {
      ALL_PROMOTIONS: 'GET_ALL_PROMOTIONS',
      ALL_FILTERED_PROMOTIONS: 'ALL_FILTERED_PROMOTIONS',
      CUSTOMER_PROMOTIONS: 'GET_CUSTOMER_PROMOTIONS',
      LIST: 'GET_ALL_PROMOTIONS',
      GET_PROMOTION: 'GET_PROMOTION',
      GET_ERROR_PROMOTION: 'GET_ERROR_PROMOTION'
    },
    BINDISCOUNTS: {
      ALL_BINDISCOUNTS: 'GET_ALL_BINDISCOUNTS',
      ALL_FILTERED_BINDISCOUNTS: 'ALL_FILTERED_BINDISCOUNTS',
      CUSTOMER_BINDISCOUNTS: 'GET_CUSTOMER_BIDISCOUNTS',
      LIST: 'GET_ALL_BINDISCOUNTS',
      GET_BINDISCOUNT: 'GET_BINDISCOUT',
      GET_BINORDERS: 'GET_BINORDERS'
    },
    LOYALTIES: {
      ALL_LOYALTIES: 'GET_ALL_LOYALTIES',
      ALL_FILTERED_LOYALTIES: 'ALL_FILTERED_LOYALTIES',
      CUSTOMER_LOYALTIES: 'GET_CUSTOMER_LOYALTIES',
      LIST: 'GET_ALL_LOYALTIES',
      GET_LOYALTY: 'GET_LOYALTY'
    },
    SMS_CAMPAIGNS: {
      ALL_CAMPAIGNS: 'GET_ALL_CAMPAIGNS',
      GET_CAMPAIGN: 'GET_CAMPAIGN',
      GET_CAMPAIGN_CUSTOMERS: 'GET_CUSTOMER_STATS'
    },
    SMS: {
      ALL_SMS_PLANS: 'GET_ALL_PLANS',
      SUBSCRIBE_SMS_PLAN: 'SUBSCRIBE_SMS',
      CARDS: 'GET_ALL_CARDS',
      SUBSCRIPTIONS: 'GET_ALL_SUBSCRIPTIONS',
      MANUAL_SUBSCRIPTIONS: 'GET_MANUAL_SUBSCRIPTIONS'
    },
    MENU_OPTIONS: {
      GET_ALL: 'GET_ALL_MENU_OPTIONS',
      SAVE: 'SAVE_MENU_OPTION',
      DELETE: 'DELETE_MENU_OPTION',
      STATUS: 'STATUS_MENU_OPTION',
      MENU_STATE: 'MENU_STATE'
    },
    MENU: {
      BULK_UPDATE: 'BULK_UPDATE_MENU',
      GET: 'GET_MENU',
      CREATE: 'CREATE_MENU',
      DELETE: 'DELETE_MENU',
      GET_DUMMY_ITEM: 'GET_DUMMY_ITEM',
      GET_ALL_MODIFIERS: 'GET_ALL_MODIFIERS',
      GET_LIST_MENUS: 'GET_LIST_MENUS'
    },
    ORDERS: {
      LIST: 'GET_ORDERS',
      LIVE_LIST: 'GET_LIVE_ORDERS',
      STATE: 'STATE_ORDERS',
      GET: 'GET_ORDER',
      GET_PROV_ORDERS: 'GET_PROV_ORDERS'
    },
    CONFIGURATIONS: {
      STATUSES: 'GET_STATUSES',
      CURRENCIES: 'GET_CURRENCIES',
      CATEGORIES: 'GET_CATEGORIES'
    },
    LOCATIONS: {
      GET: 'GET_LOCATION',
      GET_OUTLETS: 'GET_OUTLETS',
      GET_DEPARTMENTS: 'GET_DEPARTMENTS',
      GET_DELIVERY_PROCESSORS: 'GET_DELIVERY_PROCESSORS',
      CHILDREN: 'CHILD_LOCATIONS',
      OUTLETS: 'OUTLETS',
      GET_DELIVERIES: 'GET_DELIVERIES',
      IS_MASTER_LOCATION: 'IS_MASTER_LOCATION',
      SET_SELECTED_LOCATION: 'SET_SELECTED_LOCATION'
    },
    USERS: {
      ALL_USERS: 'GET_ALL_USERS',
      UPLOAD_USERS: 'GET_UPLOAD_USERS',
      GET_RIDERS: 'GET_RIDERS',
      ALL_REPORTS: 'GET_ALL_REPORTS',
      GET_CUSTOMERS: 'GET_ALL_CUSTOMERS',
      SET_EXISTING_CUSTOMER_DETAILS: 'SET_EXISTING_CUSTOMER_DETAILS',
      RESET_EXISTING_CUSTOMER_DETAILS: 'RESET_EXISTING_CUSTOMER_DETAILS',
      SET_CUSTOMER_SAVED_LOCATIONS: 'SET_CUSTOMER_SAVED_LOCATIONS',
      RESET_CUSTOMER_SAVED_LOCATIONS: 'RESET_CUSTOMER_SAVED_LOCATIONS',
      SET_CUSTOMER_SELECTED_LOCATION: 'SET_CUSTOMER_SELECTED_LOCATION',
      RESET_CUSTOMER_SELECTED_LOCATION: 'RESET_CUSTOMER_SELECTED_LOCATION',
      SET_SHOW_ADD_LOCATION_BUTTON: 'SET_SHOW_ADD_LOCATION_BUTTON'
    },
    USER_LOCATIONS: {
      DEFAULT: 'customers/user_locations',
      GET_CUSTOMERS: 'GET_ALL_CUSTOMERS'
    },
    FEEDBACKS: {
      ALL_FEEDBACKS: 'GET_ALL_FEEDBACKS'
    },
    COUNTRIES: {
      ALL_COUNTRIES: 'GET_ALL_COUNTRIES'
    },
    CURRENCIES: {
      ALL_CURRENCIES: 'GET_ALL_CURRENCIES'
    },
    PAGES: {
      ALL_PAGES: 'GET_ALL_PAGES'
    },
    APPNOTIFICATIONS: {
      ALL_NOTIFICATIONS: 'GET_ALL_NOTIFICATION',
      NOTIFICATION: 'GET_NOTIFICATION'
    },
    OTPSETTINGS: {
      ALL_SETTINGS: 'GET_ALL_SETTINGS'
    },
    BINBANKS: {
      ALL_BINBANKS: 'GET_ALL_BINBANKS'
    },
    METATAGS: {
      GET_TAGS: 'GET_LOCATION_TAGS'
    },
    ROLES: {
      ALL_ROLES: 'GET_ALL_ROLES'
    },
    STAFF: {
      ALL_STAFF: 'GET_ALL_STAFF',
      ALL_ROLES: 'GET_ALL_ROLES',
      DEFAULT: 'GET_STAFF'
    },
    REVIEWS: {
      ALL_REVIEWS: 'GET_ALL_REVIEWS'
    },
    RIDERS: {
      ALL_RIDERS_HISTORY: 'GET_ALL_RIDERSHISTORY',
      ALL_RIDERS_EARNINGS: 'GET_ALL_RIDERSEARNINGS',
      ALL_RIDERS_PAYMENTS: 'GET_ALL_RIDERSPAYMENTS',
      PAYMENT: 'GET_PAYMENT',
      ALL_RIDERS: 'GET_ALL_RIDERS',
      PAYMENT: 'GET_PAYMENT'
    },
    ACCESS: {
      ALL_ACCESS_PACKAGES: 'GET_ALL_ACCESS_PACKAGES'
    },
    TABLES: {
      ALL_TABLES: 'GET_ALL_TABLES'
    },
    ERROR_BADGE: {
      SHOW_ERROR_BADGE: 'SHOW_ERROR_BADGE'
    },
    ON_SELECT_CLICKED: 'ON_SELECT_CLICKED',
    SET_LOCATION: 'SET_LOCATION',
    LOADING: 'LOADING'
  },
  SETTINGS: {
    STATUS_POLLING_INTERVAL: 10000
  },
  RATING_COLORS: {
    0: '#F90403',
    1: '#F90403',
    2: '#FFA400',
    3: '#92D050',
    4: '#00EAD9',
    5: '#00EAD9'
  },
  DATES: {
    FORMAT: {
      DISPLAY: 'Do MMM, YYYY',
      DB_DATE: 'YYYY-MM-DD',
      EVENT_DISPLAY: 'ddd,Do MMM, YYYY'
    }
  },
  TIMES: {
    FORMAT: {
      DISPLAY: 'hh:mm A',
      DB: 'HH:mm:ss',
      PICKER: 'H:i'
    }
  },
  DATE_TIMES: {
    FORMAT: {
      DISPLAY: 'DD MMM hh:mm A',
      DISPLAYYEAR: 'DD MMM YYYY hh:mm A',
      DB: 'YYYY-MM-DD HH:mm:ss',
      TIME: 'HH:mm:ss'
    }
  },
  VARIABLES: {
    ORDER_TYPES: {
      DELIVERY: 'delivery',
      DINE_IN: 'dine_in',
      PICK_UP: 'pick_up',
      CURBSIDE_PICKUP: 'curbside_pickup'
    },
    ORDERS: {
      DEFAULT_PAGE_LENGTH: 100,
      ASAP_LABEL: 'ASAP',
      STATUSES: {
        PLACED: 'Placed',
        SEEN: 'Seen',
        BEING_PREPARED: 'Being Prepared',
        READY: 'Ready For Pickup',
        ON_THE_WAY: 'On The Way',
        COMPLETE: 'Complete',
        CANCELLED: 'Cancelled'
      },
      REASONS_TO_CANCEL: [
        'Customer changed their mind',
        'Items were not available',
        'Outside delivery zone',
        'Rider unavailable',
        'Duplicate order by customer',
        'Test order'
      ],
      PAYMENT_STATUSES: {
        PAID: 'paid'
      },
      PAYMENT_METHODS: {
        scheme: 'Card',
        credit: 'Credit',
        cash: 'Cash',
        default: 'Cash'
      },
      ORDER_TYPE_STATUSES: {
        DELIVERY: [11, 12, 13, 22, 15, 19, 36],
        DINE_IN: [11, 12, 13, 14, 15, 19, 36],
        PICK_UP: [11, 12, 13, 21, 15, 19, 36],
        CURBSIDE_PICKUP: [11, 12, 13, 21, 15, 19, 36]
      },
      ORDER_STATUS_IDS: {
        BEING_PREPARED: 13,
        CANCELLED: 19
      },
      WARNINGS: {
        UN_PAID: 'unpaid',
        CANCELLED: 'cancel'
      },
      EVENTS: {
        NEW: 'order_recieved',
        UPDATE: 'order_update',
        SYNC: 'sync_updates',
        BILL_REQUESTED: 'bill_requested'
      },
      DELIVERY_ACTIONS: {
        NOT_ASSIGNED: 0,
        BROADCAST: 23,
        SPECIFIC_RIDERS: 24,
        THIRD_PARTY: 25,
        ACCEPTED: 26,
        PICKED_UP: 27,
        ON_THE_WAY: 28,
        CANCELLED: 30,
        COMPLETE: 29
      },
      DELIVERY_ACTIONS_TO_TEXT: {
        0: 'Not Assigned',
        23: 'Broadcasted to riders',
        24: 'Specific riders requested',
        25: 'PandaGo requested',
        26: 'Accepted',
        27: 'Pickep Up',
        28: 'On the way	to customer',
        30: 'Cancelled',
        29: 'Delivered'
      },
      DELIVERY_ACTION_TEXT: {
        '0_action': 'Request Riders',
        0: 'Request Riders',
        23: 'Riders requested',
        24: 'Riders requested',
        25: 'Rider requested from PandaGo',
        26: 'Rider on way to Restaurant',
        27: 'Picked Up by Rider',
        28: 'Rider on way to Customer',
        30: 'Request Riders',
        29: 'Completed by Rider'
      },
      DELIVERY_ACTION_TEXT_COLORS: {
        '0_action': 'danger',
        0: 'danger',
        23: 'warning',
        24: 'warning',
        25: 'warning',
        26: 'primary',
        27: 'primary',
        28: 'primary',
        30: 'danger',
        29: 'success'
      }
    },
    MENUS: {
      OPTIONS: {
        DISPLAY_TYPES: {
          RADIO: 'radio',
          SELECT: 'select',
          CHECKBOX: 'checkbox'
        }
      }
    },
    PAYMENTS: {
      DEFAULT: 'CyberSource',
      DEFAULTS: 'customers/payments'
    },
    DELIVERY: {
      DEFAULT: 1 //Pandago
    },
    ANALYTICS: {
      OVERALL_STATS_CHART: {
        TOTAL_SALES: 'Total Sales',
        COMPLETED_ORDERS: 'Completed Orders',
        CANCELLED_ORDERS: 'Cancelled Orders'
      }
    },
    PROMOTIONS: {
      TYPE: {
        AUTOMATIC: 1,
        MANUAL: 0
      },
      SEND_ALL_SMS: {
        YES: 1,
        NO: 0
      },
      DAILY_SMS: {
        YES: 1,
        NO: 0
      },
      CUSTOMER_TYPE: {
        ANY: 0,
        FIRST_TIME: 1,
        RETURNING: 2
      },
      APPLICABLE_TIME_TYPE: {
        ALL_TIME: 0,
        EVERY_DAY_SPECIFIC_TIMES: 3,
        SPECIFIC_DATES: 1,
        WEEK_DAYS: 2
      },
      DISCOUNTS: {
        TYPE: {
          BASKET: 0,
          ALL_ITEMS: 1,
          SPECIFIC_ITEMS: 2
        },
        AMOUNT_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        REWARD_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1
        },
        ITEM_TYPES: {
          CATEGORY: 0,
          ITEM: 1
        }
      }
    },
    LOCATIONS: {
      OPENING_TYPE: {
        ALL_TIME: 0,
        DAILY: 1,
        FLEXIBLE: 2
      },
      DELIVERY_HOURS: {
        SAME_AS_OPENING: 0,
        ALL_TIME: 1,
        DAILY: 2,
        FLEXIBLE: 3
      },
      PICKUP_HOURS: {
        SAME_AS_OPENING: 0,
        ALL_TIME: 1,
        DAILY: 2,
        FLEXIBLE: 3
      }
    }
  },
  FIREBASE_CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  },
  FILTERS: {
    ORDERS: [
      { name: 'Live Orders', key: 'live_orders', default: true },
      { name: 'Advance Orders', key: 'advance_order', default: false },
      { name: 'Closed Orders', key: 'closed_orders', default: false },
      { name: 'Delivery Orders', key: 'delivery', default: false },
      { name: 'Dine In Orders', key: 'dine_in', default: false },
      { name: 'Curbside Orders', key: 'curbside_pickup', default: false },
      { name: 'Pickup Orders', key: 'pick_up', default: false },
      { name: 'Offline Orders', key: 'offline', default: false },
      { name: 'All Orders', key: 'all', default: false }
    ]
  },
  SORTS: {
    ORDERS: [
      { name: 'Most Recent First', key: 'DESC', default: true },
      { name: 'Oldest First', key: 'ASC', default: false }
    ]
  },
  RANDOM: {
    MIN: 1000000,
    MAX: 99999999
  },
  EVENT_TIMINGS: {
    opening_type_value: 0,
    opening_type: '24/7',
    daily_days: ['0', '1', '2', '3', '4', '5', '6'],
    daily_hours: [{ open: '00:00 AM', close: '11:59 PM' }],
    flexible_hours: [
      {
        day: '0',
        open: '12:00 AM',
        close: '11:59 PM',
        status: true
      },
      {
        day: '1',
        open: '12:00 PM',
        close: '01:59 PM',
        status: true
      },
      {
        day: '2',
        open: '10:45 AM',
        close: '23:59:00',
        status: true
      },
      {
        day: '3',
        open: '12:00 AM',
        close: '11:59 PM',
        status: true
      },
      {
        day: '4',
        open: '12:00 AM',
        close: '11:59 PM',
        status: true
      },
      {
        day: '5',
        open: '12:00 AM',
        close: '11:59 PM',
        status: true
      },
      {
        day: '6',
        open: '12:00 AM',
        close: '11:59 PM',
        status: true
      }
    ]
  },
  DUMMY_WORKING_HOURS: [
    {
      id: 0,
      weekday: 0,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 1,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 2,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 3,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 4,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 5,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 6,
      location_id: 0,
      type: 'opening',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 5460,
      weekday: 0,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 1,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 2,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 3,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 4,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 5,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 6,
      location_id: 0,
      type: 'delivery',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 0,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 1,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 2,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 3,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 4,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 5,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    },
    {
      id: 0,
      weekday: 6,
      location_id: 0,
      type: 'collection',
      status: true,
      opening_time: '00:00:00',
      closing_time: '23:59:00'
    }
  ],
  DUMMY_ORDER_TYPES: [
    {
      order_type: 'delivery',
      label: 'Delivery',
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: true
    },
    {
      order_type: 'dine_in',
      label: 'Dine In',
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: false
    },
    {
      order_type: 'pick_up',
      label: 'Pick up',
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: false
    },
    {
      order_type: 'curbside_pickup',
      label: 'CURBSIDE',
      minimum_order: 0,
      location_id: 0,
      pre_payment: false,
      active: false
    }
  ],
  DUMMY_VALUES: {
    auto_lat_lng: '1',
    opening_hours: {
      opening_type: '24/7',
      daily_days: ['0', '1', '2', '3', '4', '5', '6'],
      daily_hours: [
        {
          open: '12:00 AM',
          close: '11:59 PM'
        }
      ],
      flexible_hours: [
        {
          day: '0',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        },
        {
          day: '1',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        },
        {
          day: '2',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        },
        {
          day: '3',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        },
        {
          day: '4',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        },
        {
          day: '5',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        },
        {
          day: '6',
          open: '12:00 AM',
          close: '11:59 PM',
          status: '1'
        }
      ],
      delivery_type: '0',
      delivery_days: ['0', '1', '2', '3', '4', '5', '6'],
      delivery_hours: [
        {
          open: '12:00 AM',
          close: '11:59 PM'
        }
      ],
      collection_type: '0',
      collection_days: ['0', '1', '2', '3', '4', '5', '6'],
      collection_hours: [
        {
          open: '12:00 AM',
          close: '11:59 PM'
        }
      ]
    },
    future_orders: '0',
    future_order_days: {
      delivery: '5',
      collection: '5'
    },
    delivery_areas: {
      0: {
        name: 'Area 0',
        type: 'circle',
        minimum_order: '',
        status: true,
        default_charges: '',
        isEdit: false,
        charge: {
          1: {
            amount: 0,
            condition: 'all',
            label: 'on all orders',
            total: 0
          }
        },
        vertices:
          '[{"lat":31.52534220698884,"lng":74.35343648699197},{"lat":31.512765793011162,"lng":74.35343648699197},{"lat":31.512765793011162,"lng":74.33868351300802},{"lat":31.52534220698884,"lng":74.33868351300802}]',
        circle: '[{"center":{"lat":31.518918282526524,"lng":74.34486375936821}},{"radius":4000}]'
      }
    },
    gallery: {
      title: '',
      description: ''
    },
    currency_id: ''
  },
  DUMMY_OPTIONS: {
    auto_lat_lng: '1',
    opening_hours: {
      opening_type: '24/7',
      daily_days: ['0', '1', '2', '3', '4', '5', '6'],
      daily_hours: [
        {
          open: '09:00 PM',
          close: '11:59 PM'
        }
      ],
      flexible_hours: [
        {
          day: '0',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '1',
          open: '12:00 PM',
          close: '01:59 PM',
          status: true
        },
        {
          day: '2',
          open: '10:45 AM',
          close: '23:59:00',
          status: false
        },
        {
          day: '3',
          open: '12:00 AM',
          close: '11:59 PM',
          status: false
        },
        {
          day: '4',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '5',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '6',
          open: '21:00:00',
          close: '23:59:00',
          status: true
        }
      ],
      delivery_type: '0',
      delivery_days: ['0', '1', '2', '3', '4', '5', '6'],
      delivery_hours: [
        {
          open: '12:00 AM',
          close: '11:59 PM'
        }
      ],
      collection_type: '0',
      collection_days: ['0', '1', '2', '3', '4', '5', '6'],
      collection_hours: [
        {
          open: '04:00 PM',
          close: '06:59 PM'
        }
      ],
      events: [],
      event_holidays: [],
      delivery_flexible_hours: [
        {
          day: '0',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '1',
          open: '12:00 AM',
          close: '11:59 PM',
          status: false
        },
        {
          day: '2',
          open: '12:00 AM',
          close: '11:59 PM',
          status: false
        },
        {
          day: '3',
          open: '12:00 AM',
          close: '11:59 PM',
          status: false
        },
        {
          day: '4',
          open: '12:00 AM',
          close: '11:59 PM',
          status: false
        },
        {
          day: '5',
          open: '12:00 AM',
          close: '11:59 PM',
          status: false
        },
        {
          day: '6',
          open: '10:00:00',
          close: '23:59:00',
          status: true
        }
      ],
      pickup_flexible_hours: [
        {
          day: '0',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '1',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '2',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '3',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '4',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '5',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        },
        {
          day: '6',
          open: '12:00 AM',
          close: '11:59 PM',
          status: true
        }
      ]
    },
    future_orders: '0',
    future_order_days: {
      delivery: '5',
      collection: '5'
    },
    delivery_areas: {
      1: {
        name: 'Area 1',
        type: 'circle',
        minimum_order: '',
        status: true,
        default_charges: '',
        isEdit: true,
        charge: {
          1: {
            amount: '',
            condition: 'all',
            label: 'on all orders',
            total: 0
          }
        },
        vertices:
          '[{"lat":31.469424206988837,"lng":74.42197507823047},{"lat":31.45684779301116,"lng":74.42197507823047},{"lat":31.45684779301116,"lng":74.40723092176952},{"lat":31.469424206988837,"lng":74.40723092176952}]',
        circle: '[{"center":{"lat":31.52339807886401,"lng":74.34921320795493}},{"radius":11000}]'
      }
    },
    gallery: {
      title: '',
      description: ''
    },
    currency_id: '1'
  },
  ERP_ITEM_TYPES: {
    RAW: 'RAW',
    INTERMEDIARY: 'INTERMEDIATE',
    MENU: 'MENU',
    MODIFIER: 'MODIFIER',
    GLOBAL_MODIFIER: 'GLOBAL_MODIFIER',
    PURCHASE_UNIT: 'PURCHASE_UNIT',
    RECIPE_UNIT: 'RECIPE_UNIT',
    INVENTORY_TYPES: ['RAW', 'INTERMEDIATE', 'SELLABLE'],
    INGREDIENT_TYPES: ['RAW', 'INTERMEDIATE', 'MENU'],
    RECIPE_TYPES: ['MENU', 'INTERMEDIATE', 'GLOBAL_MODIFIER'],
    UNIT_TYPES: ['PURCHASE_UNIT', 'RECIPE_UNIT'],
    EDITABLE_INVENTORY_TYPES: ['RAW', 'SELLABLE'],
    SELLABLE: 'SELLABLE'
  },
  ADJUSTMENT_REASONS: {
    INCREASE: ['INCORRECT RECIPE', 'FOUND MISSING STASH', 'MISTAKE IN ENTRY', 'OTHER'],
    DECREASE: ['EXPIRED', 'WASTED', 'MISSING', 'RETURN TO SUPPLIER', 'MISTAKE IN ENTRY']
  },
  ADJUSTMENT_STATUS: {
    STATUS: ['PENDING', 'APPROVED']
  },
  TRANSFER_STATUS: {
    STATUS: ['PENDING', 'IN TRANSIT', 'TRANSFERRED']
  },
  INDEXDB: {
    VERSION: 1,
    PLUGD: {
      LOCATIONS: 'locations',
      LIVE_ORDERS: 'live_orders'
    },
    STORE: {
      MENUS: 'menus',
      TABLES: 'tables',
      OUTLETS: 'outlets',
      ORDER_TYPES: 'ordertypes',
      PROMOTIONS: 'promotions',
      USERS: 'users',
      USERS_LOCATIONS: 'users_locations'
    }
  }
};

export default CONSTANTS;
